export interface FilterTotalProps {
    matchStage: number;
    setMatchStage: (stage: number) => void;
    state: { IsLoading: boolean };
}
/**
 * 賽事篩選
 * @param param
 * @returns 
 */
export const FilterTotal: React.FC<FilterTotalProps> = ({ matchStage, setMatchStage, state }) => {
    return (
        <div className="boxSlide dragscroll_filter_out" style={{ marginBottom: "-20px" }}>
            <label className="filter_total">
                {["All", "Only In-Play", "Only Pre-Match"].map((stage, index) => (
                    <div className={`btn_filter ${(matchStage === index) && "on"}`} key={index}>
                        <input
                            className="form-check-input"
                            type="radio"
                            id={`inlineCheckbox${index}`}
                            checked={matchStage === index}
                            disabled={state.IsLoading}
                            onChange={state.IsLoading ? undefined : () => { setMatchStage(index) }}
                        />
                        <label htmlFor={`inlineCheckbox${index}`}>
                            <i className={`fa-solid ${(matchStage === index) ? "fa-circle-dot" : "fa-o"}`}></i>
                            {stage}
                        </label>
                    </div>
                ))}
            </label>
        </div>
    );
};