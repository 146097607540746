import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { service } from '../../services';
import { ActionType, useAppContext } from '../../components/AppContext';
import { Match } from '../../models/Match';
import { Events } from './Events';
import { CheckboxFilter, OptionItem } from '../CheckboxFilter';
import { FilterTotal } from './FilterTotal';
import moment from 'moment';
import Utils from '../../utils/Util';


export const PlaceOrder = () => {
    const [matches, setMatches] = useState<Match[] | undefined>(undefined);
    const [leagueIds, setLeagueIds] = useState<string[] | undefined>(undefined);
    const [matchStage, setMatchStage] = useState<number>(1);
    const [searchContent, setSearchContent] = useState<string>("");
    const { state, dispatch } = useAppContext();
    const timeoutIDRef = useRef<number>(0);
    let events: Match[] = [];

    useEffect(() => {
        loadData();
        return () => { };
    }, []);

    useEffect(() => {
        if (!state.IsLoading) {
            timeoutIDRef.current = window.setTimeout(() => { loadData(true) }, 5000);
        }
        return () => {
            clearTimeout(timeoutIDRef.current);
        };
    }, [matches]);

    /**
     * 查詢賽事
     * 240403 總是要ALL 改成前端過濾
     * @param e 
     */
    const loadData = async (isRefresh: boolean = false) => {
        let matchesRef: Match[] = [...matches ?? []];
        const api = service(dispatch);
        if (!isRefresh) {
            clearTimeout(timeoutIDRef.current);
            dispatch({ type: ActionType.StartLoading });
        }
        api.search({ searchContent: "*" })
            .then((response) => {
                const data = response.Data;
                if (response.Status === 0) {
                    matchesRef = data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setMatches(matchesRef);
                if (!isRefresh) {
                    dispatch({ type: ActionType.StopLoading });
                }
            });
    };

    const shouldIncludeMatch = (item: Match, matchStage: number, leagueIds: string[] | undefined, searchContent: string): boolean => {
        const isLiveCheck = (matchStage === 0) ||
            (matchStage === 1 && item.IsLive) ||
            (matchStage === 2 && !item.IsLive);
        const isIncludedLeague = !leagueIds || leagueIds.includes(item.LeagueId);
        const isSearchContent = !searchContent || searchContent.length < 3 ||
            (searchContent.includes(" ")
                ? Utils.startWith(searchContent, item.LeagueName)
                : Utils.checkArrayForStartWith(searchContent, item.LeagueName.split(" "))
            )
            ||
            (searchContent.includes(" ")
                ? Utils.startWith(searchContent, item.HomeTeam)
                : Utils.checkArrayForStartWith(searchContent, item.HomeTeam.split(" "))
            )
            ||
            (searchContent.includes(" ")
                ? Utils.startWith(searchContent, item.AwayTeam)
                : Utils.checkArrayForStartWith(searchContent, item.AwayTeam.split(" "))
            );
        return isLiveCheck && isIncludedLeague && isSearchContent;
    }

    if (matches) {
        events = matches.filter((item: Match) => shouldIncludeMatch(item, matchStage, leagueIds, searchContent));
    }
    // 選擇下單聯盟
    const dialog = () => {
        const thisEvents = (matches ?? []).sort((a: Match, b: Match) => moment(a.GameTime).diff(moment(b.GameTime)));

        const items: OptionItem[] = thisEvents.reduce((acc: OptionItem[], item: Match) => {
            const existingItem = acc.find((option: OptionItem) => option.key === item.LeagueId);
            if (!existingItem) {
                acc.push({ key: item.LeagueId, value: item.LeagueName });
            }
            return acc;
        }, []);

        dispatch({
            type: ActionType.SetContainer,
            Container: <CheckboxFilter items={items} setSelects={setLeagueIds} selectKeys={leagueIds} />
        });
    }

    return (
        <div className="container">
            <div className="listHeader">
                <div className="searchBox">
                    <label>Match / Team Name</label>
                    <input
                        className="form-control"
                        type="text"
                        aria-label="Match / Team Name"
                        placeholder="At least 3 characters"
                        disabled={state.IsLoading}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSearchContent(e.target.value) }}
                    />
                    {/*<button type="submit">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>*/}
                </div>
                <article style={{ position: "relative", height: "40px" }}>
                    {/* tabs redio filter */}
                    <FilterTotal matchStage={matchStage} setMatchStage={setMatchStage} state={state} />
                    <button className="btn_leagues" type="button" onClick={() => { dialog() }}>Select Leagues</button>
                </article>
            </div>
            {<Events events={events} />}
        </div>
    );
};

export default PlaceOrder;