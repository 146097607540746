import moment from "moment";
import './index.scss';
import { Match } from '../../models/Match';
import { Market } from '../../models/Market';
import { Order } from '../../models/Order';
import { Choice, MarketType, OrderStatus } from '../../enums';
import MarketUtils from '../../utils/MarketUtils';
import { OrderForm } from './OrderForm';
import { ActionType, useAppContext } from '../AppContext';
import { service } from '../../services';

/** 分組 */
interface GroupedEvent {
    /** 聯賽名稱 */
    LeagueName: string;
    /** 賽事列表 */
    Matches: Match[];
}

export const Events = ({ events }: { events: Match[] }) => {
    // 賽程 時間排序
    events = events= events.sort((a: Match, b: Match) => {
        // 首先按照 GameTime 升序排列
        const gameTimeComparison = moment(a.GameTime).diff(moment(b.GameTime));
    
        // 如果 GameTime 相同，则按照 TimeInfo 降序排列（null 被视为最大值）
        const mainMatchIdComparison = a.MainMatchId - b.MainMatchId;
    
        // 如果 GameTime 和 TimeInfo 都相同，则按照 MatchType 升序排列
        const matchTypeComparison = a.MatchType - b.MatchType;
    
        // 返回最终比较结果，注意顺序是先按照 GameTime，再按照 TimeInfo，最后按照 MatchType 排序
        return gameTimeComparison !== 0 ? gameTimeComparison : mainMatchIdComparison !== 0 ? mainMatchIdComparison : matchTypeComparison;
    });

    let groupedEvents: GroupedEvent[] = [];
    let currentGroup: Match[] = [];

    for (let i = 0; i < events.length; i++) {
        if (i === 0 || events[i].LeagueName === events[i - 1].LeagueName) {
            currentGroup.push(events[i]);
        } else {
            groupedEvents.push({ LeagueName: currentGroup[0].LeagueName, Matches: currentGroup });
            currentGroup = [events[i]];
        }
    }

    if (currentGroup.length > 0) {
        groupedEvents.push({ LeagueName: currentGroup[0].LeagueName, Matches: currentGroup });
    }

    return (
        <ul className="list-group box_live">
            {groupedEvents.map((group, index) => {
                return (
                    <li className="list-group-item ft_outer" key={index}>
                        <div className="btn_title_le">
                            <span><i className="fa-solid fa-ranking-star"></i>&nbsp;{group.LeagueName}</span>
                            <i className="icon_flag"></i>
                        </div>
                        {group.Matches.map((item: Match) => (
                            <Event key={item.MatchMapId} match={item} />
                        ))}
                    </li>
                );
            })}
        </ul>
    );
};

const Event = ({ match }: { match: Match }) => {
    const { state } = useAppContext();
    const findMarketType = (marketType: MarketType): boolean => {
        return match.Markets?.some((x: Market) => x.MarketType === marketType) ?? false;
    };

    return (
        <div className="bet_type_8">
            <div className="box_lebet_top">
                {/* 賽事 MATCH */}
                <div className="box_lebet_l">
                    <div className="box_star">
                        <i className="icon_star">☆</i>
                    </div>

                    <div className="box_state info_on textEllipsis">
                        {/* 賽事時間 MATCH TIME */}
                        <span className="text_time">
                            {match.IsLive ? (
                                <i className="txt_bk clock">{match.TimeInfo}</i>
                            ) : (
                                <i className="txt_bk calendar">{moment(match.GameTime).utcOffset(state.CustInfo?.UtcOffset ?? -4).format('MMMM Do YYYY, H:mm Z')}</i>
                            )}
                        </span>
                    </div>

                    {/* 隊伍資訊 TEAM INFO */}
                    <label>
                        <div className="btn_inn_team">
                            {match.IsLive ? (
                                <div className="box_score">
                                    <span className="text_point last_goal">
                                        {match.LiveScore?.[0] ?? null}
                                    </span>
                                    <span className="text_point last_goal">
                                        {match.LiveScore?.[1] ?? null}
                                    </span>
                                </div>
                            ) : null}
                            <div className="box_team teamH textEllipsis">
                                <span className="text_team" style={match.IsHome ? { fontWeight: "bold" } : {}}>
                                    {match.HomeTeam}
                                </span>
                            </div>
                            <div className="box_team teamC textEllipsis">
                                <span className="text_team" style={match.IsAway ? { fontWeight: "bold" } : {}}>
                                    {match.AwayTeam}
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="box_lebet_r " style={{ display: "flex" }}>
                    {[MarketType.FT_HDP, MarketType.FT_OU, MarketType.HT_HDP, MarketType.HT_OU].map(m => {
                        return findMarketType(m) ? <MarketPanel key={`${match.MatchMapId}-${m}`} match={match} marketType={m} /> : null;
                    })}
                </div>
            </div>
        </div>
    );
};

const MarketPanel = ({ match, marketType }: { match: Match; marketType: MarketType }) => {
    return (
        <div className="box_lebet_odd">
            <div className="head_lebet">{MarketUtils.MarketTypeName[marketType]}</div>
            {(MarketUtils.MarketTypeChoice[marketType] || []).map(choice => {
                return <ChoiceButton key={`${match.MatchMapId}-${marketType}-${choice}`} match={match} marketType={marketType} choice={choice} />;
            })}
        </div>
    );
};

const ChoiceButton = ({ match, marketType, choice }: { match: Match; marketType: MarketType; choice: Choice }) => {
    const { dispatch } = useAppContext();
    const showOrderForm = async () => {
        const api = service(dispatch);

        let order: Order = {
            MatchMapId: match.MatchMapId,
            Choice: choice,
            MarketType: marketType,
            Match: match,
            OrderLines: [],
            Status: OrderStatus.PENDING,
            OrderId: null,
            Stake: null,
            Reason: null,
            CreatedBy: null,
            CreatedTime: null
        };
        dispatch({ type: ActionType.StartLoading });
        api.checkOrder(match.MatchMapId)
            .then((response) => {
                if (response.Status === 0) {
                    order = response.Data ?? order;
                    dispatch({ type: ActionType.SetContainer, Container: <OrderForm inOrder={order} /> });
                } else {
                    alert(response.Message)
                }
            }).catch(error => {
                //console.log(error);
            }).finally(() => {
                dispatch({ type: ActionType.StopLoading });
            });
    };

    return (
        <div className="btn_lebet_odd" style={{ cursor: "pointer" }} onClick={() => showOrderForm()}>
            {MarketUtils.ChoiceName(choice, match)}
        </div>
    );
};
