import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { ActionType, useAppContext } from "../AppContext";
import { service } from "../../services";
import { useEffect, useState } from "react";
import { SystemState } from "../../enums";
import styles from './index.module.scss';


export const Header = () => {
    //matchPath
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state, dispatch } = useAppContext();
    const [systemState, setSystemState] = useState<SystemState | undefined>(undefined);
    const api = service(dispatch);

    const Logout = () => {
        dispatch({ type: ActionType.StartLoading });
        api.logout()
            .then((response) => {

            }).finally(() => {
                dispatch({ type: ActionType.Logout });
            });
    };

    useEffect(() => {
        getSystemState();
        api.noticeConut();
        const timeoutID = window.setInterval(() => { getSystemState(); }, 10000);
        const timeoutID1 = window.setInterval(async () => { await api.noticeConut(); }, 5000);

        return () => {
            window.clearInterval(timeoutID);
            window.clearInterval(timeoutID1);
        };
    }, []);

    const getSystemState = () => {
        api.getSystemState()
            .then((response) => {
                let data = response;
                if (data.Status === 0) {
                    setSystemState(data.Data as SystemState);
                }
            });
    };

    const navigateWithScroll = (path: string) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    const activeClass = (mapPath: string[]) => {
        return (mapPath.find((x) => matchPath(x, pathname))) ? styles.on : "";
    };

    ///statement/:filterType/:settle_date?
    return (
        <div className={styles.header}>
            <div className={styles.box_header}>
                <i className={`fa-solid ${styles.nook} ${systemState === SystemState.ONLINE ? styles.open : styles.close}`} >{systemState === SystemState.ONLINE ? " ONLINE" : "OFFLINE"}</i>
                <div className={`${styles.btn_home} ${activeClass(['/', '/home'])}`} onClick={() => { navigateWithScroll('/') }}>
                    <svg>
                        <path d="M17.7,11.8c0.3,0,0.5,0.2,0.5,0.5v7.2c0,0.3-0.2,0.5-0.5,0.5h-4.4c-0.3,0-0.5-0.2-0.5-0.5v-2.7h-1.6v2.7c0,0.3-0.2,0.5-0.5,0.5H6.3c-0.3,0-0.5-0.2-0.5-0.5v-7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5V19h3.5v-2.7c0-0.3,0.2-0.5,0.5-0.5h2.5c0.3,0,0.5,0.2,0.5,0.5V19h3.5v-6.7C17.2,12.1,17.4,11.8,17.7,11.8z M20.4,12.2c0.2,0.2,0.2,0.5,0,0.7C20.3,13,20.1,13,20,13c-0.2,0-0.3,0-0.3-0.1L12,5.2l-7.7,7.7c-0.2,0.2-0.5,0.2-0.7,0c-0.2-0.2-0.2-0.5,0-0.7l8-8C11.8,4.1,11.9,4,12,4c0.1,0,0.2,0.1,0.3,0.2l3.2,3.2V5.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.7L20.4,12.2z"></path>
                    </svg>
                </div>
                <div className={`${styles.boxSlide}`}>
                    <label>
                        <div className={`${styles.btn_header} ${activeClass(['/unsettled/1'])}`} onClick={() => { navigateWithScroll('/unsettled/1') }}>Rejected Bets</div>
                        <div data-badge={state.NoticeConut > 0 ? state.NoticeConut : ""} className={`${styles.badge} ${styles.btn_header} ${activeClass(['/statement/2/:settle_date?'])}`} onClick={() => { navigateWithScroll(`/statement/2`) }}>Voided Bets</div>
                        <div className={`${styles.btn_header} ${activeClass(['/unsettled/3'])}`} onClick={() => { navigateWithScroll('/unsettled/3') }}>Open Bets</div>
                        <div className={`${styles.btn_header} ${activeClass(['/statement/1/:settle_date?'])}`} onClick={() => { navigateWithScroll('/statement/1') }}>Statement</div>
                    </label>
                </div>
                <div className={`${styles.money_header}`} style={{ cursor: "default", whiteSpace: "nowrap" }}>
                    <i className="fa-regular fa-user"></i>&nbsp;
                    {state.CustInfo?.Username}</div>
                <div className={`${styles.btn_header} ${activeClass(['/changePw'])}`} onClick={() => { navigateWithScroll('/changePw') }}>Change Password</div>
                <div className={`${styles.btn_header}`} onClick={Logout}><i className="fa-solid fa-right-from-bracket"></i> Logout</div>
            </div>
        </div>
    );
};


