import React, { useState } from 'react';
import { ActionType, useAppContext } from '../AppContext';
import CheckList, { CheckOption } from './CheckList';

export interface OptionItem {
  key: string;
  value: string;
}


interface CheckboxFilterProps {
  style?: React.CSSProperties;
  className?: string;
  items: Array<OptionItem>;
  setSelects?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  selectKeys?: string[] | undefined;
}

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  style,
  className,
  items,
  setSelects,
  selectKeys
}) => {
  const { dispatch } = useAppContext();
  const [itemList, setList] = useState<CheckOption[]>(items as CheckOption[]);
  const handleFilter = () => {
    dispatch({ type: ActionType.StartLoading });
    const isAll = itemList.every((item) => item.selected);
    const selectedItems = itemList.filter(item => item.selected);
    const selectedkeys = selectedItems.map(item => item.key);
    setSelects && setSelects(isAll ? undefined : selectedkeys);
    dispatch({ type: ActionType.SetContainer, Container: undefined });
    // end filter
    dispatch({ type: ActionType.StopLoading });
  };

  return (
    <>
      <CheckList items={itemList} setList={setList} selectKeys={selectKeys} />
      <div className="buttonBar">
        <button type="submit" className="btn_submit" onClick={handleFilter} >Filter</button>
        <button type="button" className="btn_cancel" onClick={() => { dispatch({ type: ActionType.SetContainer, Container: undefined }) }}>Close</button>
      </div>
    </>
  )
};
