import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { AnonymousRoutes, PrivateRoutes } from './components/AuthRoutes';
import { Contianer } from './components/Contianer';
import { LoadingIndicator } from './components/LoadingIndicator';
import { Unfilled } from './pages/Unfilled';
import { Statement } from './pages/Statement';
import ToTopic from './components/ToTopic';
import ChangePasswordPage from './components/ChangePassword';
import { useEffect } from 'react';

const App = () => {
  useEffect (() => {
    if (process.env.REACT_APP_TITLE )
      document.title = process.env.REACT_APP_TITLE;
  });
  return (
    <>
      <Router>
        <Routes>
          <Route path='/login' element={<AnonymousRoutes/>} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path='/changePw' element={<ChangePasswordPage/>} />
            <Route path="/unsettled/:filterType" element={<Unfilled />} />
            <Route path="/statement/:filterType/:settle_date?" element={<Statement />} />
          </Route>
        </Routes>
      </Router>
      <Contianer/>
      <LoadingIndicator />
      <ToTopic />
    </>
  );
}

export default App;
