import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.scss';

interface RefreshProps {
  style?: React.CSSProperties;
  secs?: number;
  callback?: () => Promise<void>;
}

const Refresh: React.FC<RefreshProps> = ({
  style,
  secs = 5,
  callback
}) => {
  const [count, setCount] = useState(secs);
  const [isRefreshing, setRefreshing] = useState<boolean>(false);

  const handleRefresh = useCallback(async () => {
    if (callback) {
      setRefreshing(true);
      if (count > 0) {
        setCount(0);
      }
      await callback().finally(
        () => {
          setRefreshing(false);
          setCount(secs);
        }
      );
    }
  }, [callback, secs]);

  useEffect(() => {
    let timer: number = 0;
    if (count <= 0) {
      if (!isRefreshing)
        handleRefresh();
    } else {
      timer = window.setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }

    return () => {
      window.clearTimeout(timer);
    };
  }, [count]);

  return (
    <button type='button'
      className={styles.buttonRefresh}
      style={style}
      onClick={isRefreshing ? undefined : handleRefresh}
      disabled={isRefreshing}
    >
      <i
        className={`fa-solid fa-rotate ${styles.fleft} ${isRefreshing && styles.rotate
          }`}
      ></i>
      <span className={styles.sesc}>{isRefreshing ? undefined : count}</span>
    </button>
  );
};

export default Refresh;
