import { useEffect, useState } from "react";
import { BetDetail } from "../../models/Satement";
import { service } from "../../services";
import { useAppContext } from "../AppContext";
import Utils from "../../utils/Util";
import { useParams } from "react-router-dom";
import { StatementDetailItem } from "../StatementDetailItem";

export const StatementDetail = () => {
    const [statementBets, setStatementBets] = useState<BetDetail[] | undefined>(undefined);
    const { state, dispatch } = useAppContext();
    const api = service(dispatch);
    const { settle_date, filterType } = useParams();

    useEffect(() => {
        return () => {
            setStatementBets(undefined);
        };
    }, [settle_date, filterType]);

    useEffect(() => {
        let timeoutIDRef: number = 0;
        if (!statementBets) {
            api.getBetDetail(settle_date ?? '', parseInt(filterType ?? '0'))
                .then((response) => {
                    if (response.Status === 0 && response.Data) {
                        setStatementBets(response.Data);
                        const betIds: number[] = (response.Data as BetDetail[])
                            .filter((betDetail) => betDetail.OrderLines.some((betLine) => betLine.IsUnRead === false))
                            .map((betDetail) => betDetail.OrderLines.filter((betLine) => betLine.IsUnRead === false))
                            .flat()
                            .map((betLine) => betLine.BetId);

                        if (betIds.length > 0) {
                            api.setRead(betIds).then((response) => {
                                if (response.Status === 0) {
                                    api.noticeConut();
                                }
                            }).catch((error) => {

                            });
                        }
                    }
                }).catch((error) => {

                });
        } else {
            timeoutIDRef = window.setTimeout((() => RefreshBetDetail({ ...statementBets })), 5000);
        }
        return () => {
            if (timeoutIDRef !== 0)
                window.clearTimeout(timeoutIDRef);
        };
    }, [statementBets]);

    const RefreshBetDetail = (_statementBets: BetDetail[]) => {
        api.getBetDetail(settle_date ?? '', parseInt(filterType ?? '0'))
            .then((response) => {
                if (response.Status === 0 && response.Data) {
                    _statementBets = response.Data;
                }
            }).finally(() => {
                setStatementBets(_statementBets);
            });
    };

    let totalStake: number = 0;
    let totalWinLoss: number = 0;

    let StatementDetailList = statementBets?.map((statementBet, index) => {
        totalStake += statementBet.OrderLines.reduce((sum, model) => sum + (model.Stake ?? 0), 0);
        totalWinLoss += statementBet.OrderLines.reduce((sum, model) => sum + model.WinLoss, 0);
        return <StatementDetailItem key={statementBet.OrderId} statementBet={statementBet} />;
    });

    return (
        <>
            {StatementDetailList}
            <div className="ope_info_foot">
                <span>Total Stake:</span>
                <span className="word_white">{Utils.formatNumberWithCommas(totalStake)}</span>
                {filterType !== '2' ? <span className={totalWinLoss < 0 ? "word_lightred" : "word_lightgreen"}>{Utils.formatNumberWithCommas(totalWinLoss)}</span> : null}
            </div>
        </>
    );
};