import styles from './index.module.scss';

interface InputNumberProps {
    min?: number;
    step?: number;
    max?: number;
    placeholder?: string;
    value?: number;
    className?: string;
    style?: React.CSSProperties;
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputNumber: React.FC<InputNumberProps> = ({
    min,
    max,
    step = 1,
    value = 0,
    style,
    onChange
}) => {
    const handleIncrement = () => {
        if (value && step) {
            if (max && (value + step) > max) return;
            value += step;
            onChange && onChange({
                target: {
                    value: value.toFixed(2)
                }
            } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleDecrement = () => {
        if (value && step) {
            if (min && (value - step) < min) return;
            value -= step;
            onChange && onChange({
                target: {
                    value: value.toFixed(2)
                }
            } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    return (
        <div className={styles.inputNumber} style={style}>
            <i className="fa-solid fa-minus" onClick={handleDecrement}></i>
            <input type="number" step={step} min={min} max={max} value={value} onChange={onChange && onChange} />
            <i className="fa-solid fa-plus" onClick={handleIncrement}></i>
        </div>
    );
};