export default class Utils {
    static formatNumberWithCommas(amount: number): string {
        // 使用 toLocaleString 方法，並指定 'en-US' 作為語言參數
        return amount.toLocaleString('en-US');
    }

    static checkArrayForStartWith(input: string, arr: string[]): boolean {
        for (const item of arr) {
            if (item.toLowerCase().startsWith(input.toLowerCase())) {
                return true;
            }
        }

        return false; // 未找到匹配项
    }

    static startWith(input: string, item: string): boolean {
        return item.toLowerCase().startsWith(input.toLowerCase());
    }
}
