import moment from 'moment';
import { OrderStatus } from '../../enums';
import { Order, OrderLine } from '../../models/Order';
import MarketUtils from '../../utils/MarketUtils';
import OddsUtils from '../../utils/OddsUtils';
import Utils from '../../utils/Util';
import { useAppContext } from '../AppContext';

export const OrderItem = ({ order }: { order: Order }) => {
    const { state } = useAppContext();
    const marketType = order.MarketType;
    let orderStatus: OrderStatus = order.Status;
    let totalStake: number | undefined = undefined;

    const orderLines = order.OrderLines.map((orderLine: OrderLine, index: number) => {
        let point: number = orderLine.Line;
        let strPoint: string = OddsUtils.ConvertPoint(marketType, point);
        let price = orderLine.Price;

        if (orderLine.Stake) {
            totalStake = (totalStake ?? 0) + (orderLine.Stake ?? 0);
        }
        return (
            <ul key={orderLine.Line} className="ope_info_txt">
                {index === 0 ? <li className="ope_txt_team">{order.Match.IsLive ? <span className="word_red">(In-Play)</span> : ""} {MarketUtils.MarketTypeName[order.MarketType]}<b className="word_lightgreen"></b> {order.Match.IsLive ? <b className='word_yellow'>({order.Match.LiveScore?.[0] ?? 0} - {order.Match.LiveScore?.[1] ?? 0})</b> : null}</li> : null}
                <li className="ope_txt_odd">{MarketUtils.ChoiceName(order.Choice, order.Match)} <b className="word_yellow"> {strPoint}</b> <code>@</code> <b className="word_red">{price}</b> {orderLine.Stake ? <b className="word_black">${Utils.formatNumberWithCommas(orderLine.Stake)}</b> : null}</li>
                <li className="delay_reason">
                    <span className="word_red">*DELAY_REASON*</span>
                </li>
            </ul>
        );
    });

    const orderStatusClass: { [key in OrderStatus]?: string } = {
        [OrderStatus.PREPARE]: "word_gray",
        [OrderStatus.DELETED]: "word_fail",
        [OrderStatus.RUNNING]: "word_blue",
        [OrderStatus.STOP]: "word_lightred",
        [OrderStatus.SUSPENDED]: "word_lightred",
        [OrderStatus.CANCELLED]: "word_gray",
        [OrderStatus.PENDING]: "word_gray",
        [OrderStatus.REJECTED]: "word_fail",
        [OrderStatus.CONFIRMED]: "word_green"
    }

    return (
        <div id="div_show" className="ord_outside_box">
            {/* <!-- title --> */}
            <div className="ope_info_title">
                <ul>
                    <li className="word_white">{order.Match.LeagueName}</li>
                    <li>
                        {order.Match.HomeTeam} <b className="word_lightyellow"></b>
                        <span><b>v</b></span>
                        {order.Match.AwayTeam} <b className="word_lightyellow"></b>
                    </li>
                </ul>
            </div>
            {orderLines}
            <ul className="ope_info_txt">
                <li className="ope_txt_win">
                    <span>{totalStake ? <>Total Stake: <b className="word_black">{Utils.formatNumberWithCommas(totalStake)}</b></> : <>Maximum Stake: <b className="word_black">{Utils.formatNumberWithCommas(order.Stake ?? 0)}</b></>}</span>
                    <span>Order Creator: <b className="word_black">{order.CreatedBy}</b></span>
                </li>
                <li className="ope_txt_time">
                    <span>{order.OrderId}</span>
                    <span>{moment(order.CreatedTime).utcOffset(state.CustInfo?.UtcOffset ?? -4).format('Do MMM. YYYY, H:mm Z')}</span>
                </li>
                <li>
                    <span className={orderStatusClass[orderStatus]}>
                        <b>{MarketUtils.OrderStatusName[orderStatus]}</b>
                    </span>
                </li>
                {order.Reason && (
                    <li className="load_inorder">
                        <i className="fa-solid fa-circle-info">&nbsp;</i>
                        <span className="">{order.Reason}</span>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default OrderItem;