import { useEffect, useState } from "react";
import { ActionType, useAppContext } from "./AppContext";
import { service } from "../services";
import { CustInfo } from "../models/CustInfo";
import { Loading } from "./LoadingIndicator/Loading";
import { Navigate, Outlet } from "react-router-dom";
import { Login } from "../pages/Login";
import { Header } from "./Header";
declare var confirm: any;

const useAuthentication = () => {
    const { state, dispatch } = useAppContext();
    const [retry, setRetry] = useState<number>(0);

    useEffect(() => {
        if (state.IsAuthenticated === undefined) {
            service(dispatch)
                .userInfo()
                .then((response) => {
                    if (!response.Data) {
                        dispatch({ type: ActionType.Logout });
                    } else {
                        let custInfo: CustInfo = response.Data;
                        dispatch({ type: ActionType.Login, CustInfo: custInfo });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status !== 401) {
                        if (confirm(`${error.message}\r\nDo you want to try again?`)) {
                            setRetry(retry + 1);
                        } else {
                            //dispatch({ type: ActionType.Logout });
                        }
                    }
                });
        }
    }, [retry]);

    return state.IsAuthenticated;
};

export const PrivateRoutes = () => {
    const isAuthenticated = useAuthentication();

    if (isAuthenticated === undefined) {
        return <Loading />;
    } else if (isAuthenticated === true) {
        return <><Header /><div className="content_l"><Outlet /></div></>;
    } else {
        return <Navigate to="/login" replace />;
    }
};

export const AnonymousRoutes = () => {
    const isAuthenticated = useAuthentication();

    if (isAuthenticated === undefined) {
        return <Loading />;
    } else if (isAuthenticated === true) {
        return <Navigate to="/" replace />;
    } else {
        return <Login />;
    }
};
