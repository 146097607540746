import React, { createContext, useReducer, useContext, ReactNode } from 'react';
//import { StateInfo } from '../models/StateInfo';
import { OddsType } from '../enums';
//import { ActionType } from '../enums/eActionType';
import { CustInfo } from '../models/CustInfo';
/** 狀態資訊 */
type StateInfo = {
  /** 用戶資訊 */
  CustInfo: CustInfo | undefined;
  /** 已驗證 */
  IsAuthenticated: boolean | undefined;
  /** 通知 */
  Notify: string | undefined;
  /** 是否載入中 */
  IsLoading: boolean;
  /** Dialog容器 */
  Container: JSX.Element | undefined;

  NoticeConut: number;
}

/** 操作類型 */
enum ActionType {
  /** 登入 */
  Login,
  /** 登出 */
  Logout,
  /** 設定賠率類型 */
  SetOddsType,
  /** 通知 */
  Notify,
  /** 設定Dialog容器 */
  SetContainer,
  /** 開始載入 */
  StartLoading,
  /** 停止載入 */
  StopLoading,
  /** 停止載入 */
  SetNoticeConut
}

const initialState: StateInfo = {
  IsAuthenticated: undefined,
  CustInfo: undefined,
  Notify: undefined,
  Container: undefined,
  IsLoading: false,
  NoticeConut: 0
};

type Action =
  | { type: ActionType.Login; CustInfo: CustInfo }
  | { type: ActionType.Logout }
  | { type: ActionType.SetOddsType; OddsType: OddsType }
  | { type: ActionType.Notify; Notify: string }
  | { type: ActionType.SetContainer; Container: JSX.Element | undefined }
  | { type: ActionType.StartLoading }
  | { type: ActionType.StopLoading }
  | { type: ActionType.SetNoticeConut, NoticeConut: number };

const reducer = (state: StateInfo, action: Action): StateInfo => {
  switch (action.type) {
    case ActionType.Login:
      action.CustInfo.UtcOffset = action.CustInfo.UtcOffset ?? -4;
      return { ...state, IsAuthenticated: true, CustInfo: action.CustInfo };
    case ActionType.Logout:
      return { ...initialState, IsAuthenticated: false };
    case ActionType.SetOddsType:
      if (state.CustInfo && state.CustInfo.OddsType !== action.OddsType) {
        return { ...state, CustInfo: { ...state.CustInfo, OddsType: action.OddsType } };
      } else {
        return state;
      }
    case ActionType.Notify:
      return { ...state, Notify: action.Notify };
    //return { ...state, Notify: action.Notify };
    case ActionType.SetContainer:
      return { ...state, Container: action.Container };
    case ActionType.StartLoading:
      return { ...state, IsLoading: true };
    case ActionType.StopLoading:
      return { ...state, IsLoading: false };
    case ActionType.SetNoticeConut:
      if (state.NoticeConut !== action.NoticeConut) {
        return { ...state, NoticeConut: action.NoticeConut };
      } else {
        return state;
      }
    default:
      return state;
  }
};

//================================================
interface AppContextProps {
  state: StateInfo;
  dispatch: React.Dispatch<Action>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};

export { AppContextProvider, useAppContext, ActionType };