import React, { FormEvent, useEffect, useState } from 'react';
import { ActionType, useAppContext } from '../AppContext';
import { service } from '../../services';
import styles from './index.module.scss';
import { InputLabel } from '../InputLabel';

const ChangePasswordPage = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { state, dispatch } = useAppContext();
    //IsLoading
    const api = service(dispatch);
    const changePwd = (oldPassword: string, newPassword: string) => {
        dispatch({ type: ActionType.StartLoading });
        api.changePwd(oldPassword, newPassword)
            .then((response) => {
                if (response.Status !== 0) {
                    setErrorMessage(response.Message);
                } else {
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    //setErrorMessage('Password changed successfully');
                    alert('Password changed successfully');
                }
            }).catch((e) => {
                setErrorMessage(e.message);
            }).finally(() => {
                dispatch({ type: ActionType.StopLoading });
            });
    };

    const handleChangePassword = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // 在這裡處理更換密碼的邏輯
        if (!currentPassword || !newPassword || !confirmPassword) {
            setErrorMessage('Please fill in all fields');
        } else
            if (newPassword !== confirmPassword) {
                setErrorMessage('The new password does not match the confirmation password');
            } else {
                changePwd(currentPassword, newPassword);
            }
    };

    useEffect(() => {
        if (!state.IsLoading)
            setErrorMessage('');
    }, [currentPassword, newPassword, confirmPassword]);

    return (
        <>
            <div className={styles.content_acchelp}>
                <div className={styles.title_help} style={{marginBottom: "32px"}}>
                    <div className={styles.btn_back}></div>
                    <span>Change Password</span>
                </div>
                <div className={styles.changePassword}>
                    <div className={styles.noteBox}>
                        <ul className={styles.noteBox2}>
                            <li>The password must be at least 6 characters long.</li>
                            <li>No spaces allowed.</li>
                        </ul>
                    </div>
                    <form autoComplete="off" onSubmit={state.IsLoading ? undefined : handleChangePassword}>
                        <div>
                            <InputLabel
                                tooltip="Current Password"
                                type="password"
                                autoComplete="off"
                                value={currentPassword}
                                minLength={6}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />

                            <InputLabel
                                tooltip="New Password"
                                type="password"
                                autoComplete="off"
                                value={newPassword}
                                minLength={6}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />

                            <InputLabel
                                tooltip="Confirm Password"
                                type="password"
                                autoComplete="off"
                                value={confirmPassword}
                                minLength={6}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {errorMessage && <span className="text_error">{errorMessage}</span>}
                            <div className="btn_chgpwd">
                                <button type="submit" className="btn_submit radius">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordPage;



