import { useState, useEffect, useRef } from 'react';
import { FormEvent } from 'react';
import { service } from '../../services';
import { CustInfo } from '../../models/CustInfo';
import { ActionType, useAppContext } from '../../components/AppContext';
import styles from './index.module.scss';
import { InputLabel } from '../../components/InputLabel';
export const Login = () => {
    const isDev = process.env.NODE_ENV === 'development';
    const [username, setUsername] = useState<string>((isDev && process.env.REACT_APP_USERID )? process.env.REACT_APP_USERID : "");
    const [password, setPassword] = useState<string>((isDev && process.env.REACT_APP_PASSWORD ) ? process.env.REACT_APP_PASSWORD : "");
    const [errMessage, setError] = useState<string | undefined>(undefined);
    const [isLoading] = useState<boolean>(false);
    const refUserid = useRef<HTMLInputElement>(null);
    const refPassword = useRef<HTMLInputElement>(null);
    const statusCode = useRef<number>(0);
    const { dispatch } = useAppContext();
    const SignIn = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        statusCode.current = 0;
        let errContent: string | undefined = undefined;
        if (username === "") {
            errContent = "Username is required";
            statusCode.current = 1;
            refUserid.current?.focus?.();
        }
        if (password === "") {
            errContent = errContent ? `${errContent}, Password is required` : "Password is required";
            statusCode.current += 2;
            refPassword.current?.focus?.();
        }

        if (statusCode.current > 0) {
            setError(errContent);
            return;
        }

        dispatch({ type: ActionType.StartLoading });
        //loadContext.open();
        await service(dispatch).userLogin({ Username: username ?? "", Password: password ?? "" })
            .then(async (response) => {
                let status: number = response.Status;
                let message: string = response.Message;
                console.log(response.Status);
                if (status === 0) {
                    await service(dispatch).userInfo()
                        .then((response) => {
                            if (!response.Data) dispatch({ type: ActionType.Logout });//throw new Error('Data is empty');
                            let custInfo: CustInfo = response.Data; // 取得使用者資訊
                            /**
                             * Dispatches a login action with the provided customer information.
                             * @param {object} custInfo - The customer information.
                             */
                            console.log(custInfo);
                            dispatch({ type: ActionType.Login, CustInfo: custInfo });
                            //navigate("/home");
                        }).catch((error) => {
                            // 2024/01/10 error hanndle test
                            setError(error.message);
                        });
                } else {
                    statusCode.current = 3;
                    refUserid.current?.focus?.();
                    setError(message);
                }

            }).catch((error) => {
                // 2024/01/10 error hanndle test
                setError(error.message);
            }).finally(() => {
                dispatch({ type: ActionType.StopLoading });
            });
    }

    useEffect(() => {
        setError(undefined);
    }, [username, password]); // Only re-run the effect if count changes

    return (
        <form id="form_login" onSubmit={isLoading ? undefined : SignIn}>
            <div className={styles.box_login}>
                {/* account */}
                <InputLabel
                    inputRef={refUserid}
                    className={(statusCode.current === 1 || statusCode.current === 3) ? "error":""}
                    type="text"
                    autoComplete="off"
                    value={username}
                    onChange={(e) => { setUsername(e.target.value) }}
                    tooltip="Username"
                    required
                />
                {/* password */}
                <InputLabel
                    inputRef={refPassword}
                    className={(statusCode.current === 2 || statusCode.current === 3) ? "error":""}
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                    tooltip="Password"
                    required
                />
                {errMessage ? <span className={styles.text_error}>{errMessage}</span> : null}
                <input className={styles.btn_login} type="submit" value="LOG IN" disabled={isLoading} />
            </div>
        </form>
    );
};



