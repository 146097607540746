/**
 * Represents the type of odds.
 */
export enum OddsType {
    /**
     * Hong Kong odds.
     */
    HK = 1,
    /**
     * Malay odds.
     */
    MALAY = 2,
    /**
     * Indonesian odds.
     */
    INDO = 3,
    /**
     * Decimal odds.
     */
    DECIMAL = 4,
    /**
     * American odds.
     */
    AMERICAN = 5,
    /**
     * Fractional odds.
     */
    FRACTIONAL = 6,
}

export enum Choice {
    NONE = 0,
    HOME = 1,
    AWAY = 2,
    DRAW = 3,
    OVER = 4,
    UNDER = 5,
    ODD = 6,
    EVEN = 7
}

export enum MarketType {
    NONE = 0,
    FT_HDP = 1,
    FT_OU = 2,
    FT_OE = 3,
    FT_1X2 = 4,
    HT_HDP = 5,
    HT_OU = 6,
    HT_OE = 7,
    HT_1X2 = 8
}

export enum OrderStatus {
    PREPARE = 0,
    DELETED = 1,
    RUNNING = 2,
    STOP = 3,
    SUSPENDED = 4,
    CANCELLED = 5,
    VAR = 6,
    PENDING = 7,
    REJECTED = 8,
    CONFIRMED = 9
}

export enum SystemState {
    OFFLINE = 1,
    ONLINE = 2
}

export enum BetStatus {
    WAITING = 0,    // useless cause equal "PENDING"
    PENDING = 1,
    CONFIRMED = 2,
    REJECTED = 3,
    FAILED = 4,
    CANCELLED = 5,
    SETTLED = 6,
    DELETED = 7,
    ABNORMAL = 8,
    SUSPENDED = 9,
    VAR = 10,
    SENT = 87,
    UNKNOWN = 99,
    UNDEFINED = 78
}

export enum ScoreType {
    UNKNOW = 0,
    NORMAL = 1,
    CORNER = 2,
    BOOKING = 3,
    EXTRATIME = 4,
    PKHDP = 5,
    PKOU = 6,
    EXTRATIMECORNER = 7,
    EXTRATIMEBOOKING = 8
}