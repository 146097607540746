import { Match } from "../../models/Match";
import { BetDetail } from "../../models/Satement";
import MarketUtils from "../../utils/MarketUtils";

/**
 * 賽事狀態
 */
const MatchStatus = (match: Match, statementBet: BetDetail) => {
    return (
        <li className="ope_txt_team history">
            <span>
                {/* 賽況 */}
                {match.IsLive &&
                    <b className="word_red">(In-Play)</b>
                }

                {/* 盤口 className="word_lightgreen"*/}
                <b>&nbsp;{MarketUtils.MarketTypeName[statementBet.MarketType]}&nbsp;</b>

                {/* 當下比分 */}
                {match.IsLive &&
                    <b className='word_yellow'>({match.LiveScore?.[0] ?? 0} - {match.LiveScore?.[1] ?? 0})</b>
                }
            </span>
            {/* 結果比分 */}
            {statementBet.Result &&
                <span className="ope_point">{statementBet.Result?.[0]} - {statementBet.Result?.[1]}</span>
            }
        </li>
    );
};

export default MatchStatus;