import { ChangeEvent, useEffect } from "react";
import { OptionItem } from ".";
import styles from './index.module.scss';

interface CheckListProps {
    style?: React.CSSProperties;
    className?: string;
    items: Array<CheckOption>;
    setList: React.Dispatch<React.SetStateAction<CheckOption[]>>;
    selectKeys?: string[] | undefined;
}


export interface CheckOption extends OptionItem {
    selected: boolean;
}

const CheckList: React.FC<CheckListProps> = ({
    style,
    className,
    items,
    setList,
    selectKeys
}) => {
    useEffect(() => {
        const updatedItems = items.map((item) => ({
            ...item,
            selected: selectKeys !== undefined && selectKeys.includes(item.key)
        }));
        setList(updatedItems);
    }, []);

    const isAll = items.every((item) => item.selected);
    const handleCheckboxChange = (checkOption: CheckOption) => {
        checkOption.selected = !checkOption.selected;
        setList([...items]);
        //console.log(select);
    };
    const handleCheckboxSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        let isAll1 = items.every((item) => item.selected);
        items.forEach((item) => {
            item.selected = !isAll1;
        });
        setList([...items]);
    };

    return (
        <div className={`${styles.checkboxFilter} ${className && className}`} style={style} tabIndex={100}>
            {items.length === 0 && <p className={styles.noItems}>There are no leagues to filter</p>}
            <ul className={styles.items}>
                {items && items.length > 0 && (
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                checked={isAll}
                                onChange={handleCheckboxSelectAll}
                            />
                            {isAll ? "Cancel Select" : "Select All"}
                        </label>
                    </li>
                )}
                {items &&
                    items.map((item) => (
                        <li key={item.key}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={() => handleCheckboxChange(item)}
                                />{item.value}
                            </label>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default CheckList;