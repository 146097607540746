import { Choice, MarketType, OrderStatus, OddsType } from '../enums';
import { Match } from '../models/Match';

interface MarketUtilsType {
    MarketTypeName: { [key in MarketType]?: string };
    MarketTypeChoice: { [key in MarketType]?: Choice[] };
    OrderStatusName: { [key in OrderStatus]?: string };
    OddsTypeName: { [key in OddsType]?: string };
    OrderStatusClass: { [key in OrderStatus]?: string };
    ChoiceName: (choice: Choice, match: Match) => string;
    MarketTypeMappings: { [key: string]: MarketType };
}

const MarketUtils: MarketUtilsType = {
    MarketTypeName: {
        [MarketType.FT_HDP]: "Handicap",
        [MarketType.FT_OU]: "Goals O/U",
        [MarketType.HT_HDP]: "1st Half Handicap",
        [MarketType.HT_OU]: "1st Half Goals O/U",
    },

    MarketTypeChoice: {
        [MarketType.FT_HDP]: [Choice.HOME, Choice.AWAY],
        [MarketType.FT_OU]: [Choice.OVER, Choice.UNDER],
        [MarketType.HT_HDP]: [Choice.HOME, Choice.AWAY],
        [MarketType.HT_OU]: [Choice.OVER, Choice.UNDER]
    },

    OrderStatusName: {
        [OrderStatus.PREPARE]: "PREPARE",
        [OrderStatus.DELETED]: "DELETE",
        [OrderStatus.RUNNING]: "RUNNING",
        [OrderStatus.STOP]: "STOP",
        [OrderStatus.SUSPENDED]: "SUSPENDED",
        [OrderStatus.CANCELLED]: "CANCELLED",
        [OrderStatus.VAR]: "VAR",
        [OrderStatus.PENDING]: "PENDING",
        [OrderStatus.REJECTED]: "REJECTED",
        [OrderStatus.CONFIRMED]: "CONFIRMED"
    },
    OddsTypeName: {
        [OddsType.DECIMAL]: "Euro Decimal Odds",
        [OddsType.HK]: "Hong Kong Odds",
        [OddsType.MALAY]: "Malay Odds",
        [OddsType.INDO]: "Indo Odds",
        [OddsType.AMERICAN]: "American Odds"
    },

    OrderStatusClass: {
        [OrderStatus.PREPARE]: "bg-secondary ",
        [OrderStatus.DELETED]: "bg-danger",
        [OrderStatus.RUNNING]: "bg-primary",
        [OrderStatus.STOP]: "bg-secondary ",
        [OrderStatus.SUSPENDED]: "bg-secondary ",
        [OrderStatus.CANCELLED]: "bg-danger",
        [OrderStatus.VAR]: "bg-danger",
        [OrderStatus.PENDING]: "bg-secondary ",
        [OrderStatus.REJECTED]: "bg-danger",
        [OrderStatus.CONFIRMED]: "bg-success"
    },
    ChoiceName: (choice: Choice, match: Match) => {
        switch (choice) {
            case Choice.NONE:
                return "None";
            case Choice.HOME:
                return match.HomeTeam;
            case Choice.AWAY:
                return match.AwayTeam;
            case Choice.DRAW:
                return "Draw";
            case Choice.OVER:
                return "Over";
            case Choice.UNDER:
                return "Under";
            case Choice.ODD:
                return "Odd";
            case Choice.EVEN:
                return "Even";
            default:
                return "Invalid Choice";
        }
    },
    MarketTypeMappings: {
        "NONE": MarketType.NONE,
        "HDP": MarketType.FT_HDP,
        "OU": MarketType.FT_OU,
        "OE": MarketType.FT_OE,
        "1X2": MarketType.FT_1X2,
        "HT_HDP": MarketType.HT_HDP,
        "HT_OU": MarketType.HT_OU,
        "HT_OE": MarketType.HT_OE,
        "HT_1X2": MarketType.HT_1X2
    }
};


export default MarketUtils;
