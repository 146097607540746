import { Loading } from './Loading';
import { useAppContext } from "../../components/AppContext";

export const LoadingIndicator = () => {
    const { state } = useAppContext();

    return (
        state.IsLoading ?
            <Loading /> : null
    );
};
