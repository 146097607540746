import { MarketType, OddsType } from '../enums';
import Decimal from 'decimal.js';

export default class OddsUtils {
    static ConvertOddsType(hkOdds: number, oddsType: OddsType): number {
        const Hk2Decimal = (hkOdds: number): number => {
            return new Decimal(hkOdds).plus(1).toNumber();
        }

        const Hk2Malay = (hkOdds: number): number => {
            if (hkOdds > 1.0) {
                hkOdds = -1.0 / hkOdds;
                hkOdds = Math.trunc(hkOdds * 1000) / 1000;
            }

            return hkOdds;
        }

        switch (oddsType) {
            case OddsType.DECIMAL:
                return Hk2Decimal(hkOdds);
            case OddsType.MALAY:
                return Hk2Malay(hkOdds);
            default:
                return hkOdds;
        }
    }
    static OddsStep(oddsType: OddsType): number {
        if (oddsType === OddsType.MALAY)
            return 0.001;
        else
            return 0.01;
    }
    static OddsMax(oddsType: OddsType): number | undefined {
        if (oddsType === OddsType.MALAY)
            return 1;
        else
            return undefined;
    }
    static OddsMin(oddsType: OddsType): number {
        switch (oddsType) {
            case OddsType.DECIMAL:
                return 1.01;
            case OddsType.MALAY:
                return -0.995;
            case OddsType.HK:
                return 0.01;
            default:
                return 0.01;
        }
    }
    static ConvertPoint(marketType: MarketType, point: number): string {
        let dPoint = Math.abs(point);
        // 分解數字
        const integerPart = Math.floor(dPoint);
        const decimalPart = dPoint - integerPart;
        let strPoint: string = dPoint.toString();
        // 判斷小數部分
        if (decimalPart === 0.25 || decimalPart === 0.75) {
            strPoint = `${dPoint - 0.25}/${dPoint + 0.25}`;
        } else {
            strPoint = dPoint.toString();
        }

        if (marketType === MarketType.FT_HDP || marketType === MarketType.HT_HDP) {
            strPoint = `${Math.sign(point) >= 0 ? '+' : '-'}${strPoint}`;
        }

        return strPoint;
    }
}
