import moment from 'moment';
import { BetStatus } from '../../enums';
import MarketUtils from '../../utils/MarketUtils';
import OddsUtils from '../../utils/OddsUtils';
import Utils from '../../utils/Util';
import { BetLine, BetDetail } from '../../models/Satement';
import { useAppContext } from '../AppContext';
import MatchStatus from './MatchStatus';

export const StatementDetailItem = ({ statementBet }: { statementBet: BetDetail }) => {
    //let point: number = order.Choice === Choice.AWAY ? line.Line * -1 : line.Line;
    //let strPoint: string = OddsUtils.ConvertPoint(marketType, point);
    const { state } = useAppContext();
    let orderStake: number = 0;
    let orderWinLoss: number | undefined = undefined;
    const match = statementBet.Match;
    const marketType = statementBet.MarketType;

    /**
     * group by match
     */
    const orderLines = statementBet.OrderLines.map((orderLine: BetLine, index: number) => {
        let point: number = orderLine.Line;
        let strPoint: string = OddsUtils.ConvertPoint(marketType, point);
        let price = orderLine.Price;
        orderStake += orderLine.Stake ?? 0;
        if (orderLine.Status === BetStatus.SETTLED) {
            orderWinLoss = (orderWinLoss ?? 0) + orderLine.WinLoss;
        }
        return (
            <ul key={orderLine.Line} className="ope_info_txt">
                {index === 0 && MatchStatus(match, statementBet)}
                
                <li className="ope_txt_odd">
                    {MarketUtils.ChoiceName(statementBet.Choice, match)}
                    <b className="word_yellow"> {strPoint}</b> <code>@</code> 
                    <b className="word_red">{price}</b> 
                    <b className="word_black">${Utils.formatNumberWithCommas(orderLine.Stake ?? 0)}</b>
                </li>
                {orderLine.Status === BetStatus.SETTLED && 
                    <li className="ope_txt_win">
                        <span>Win / Loss: 
                            <b className={`word_black ${orderLine.WinLoss < 0 ? "word_red" : "word_green"}`}>
                                {Utils.formatNumberWithCommas(orderLine.WinLoss)}
                            </b>
                        </span>
                    </li>
                }
                {/* 顯示 刪除原因 */}
                {orderLine.DeleteReason && 
                    <li className="load_inorder">
                        <i className="fa-solid fa-circle-info">&nbsp;</i>
                        <span>
                            {orderLine.DeleteReason}
                        </span>
                    </li>
                }
            </ul>
        );
    });

    return (
        <div className="ord_outside_box">
            {/* <!-- title --> */}
            <div className="ope_info_title">
                <ul>
                    <li className="word_white">{match.LeagueName}</li>
                    <li>
                        {match.HomeTeam} <b className="word_lightyellow"></b>
                        <span><b>v</b></span>
                        {match.AwayTeam} <b className="word_lightyellow"></b>
                    </li>
                </ul>
            </div>
            {orderLines}
            <ul className="ope_info_txt">
                <li className="ope_txt_win">
                    <span>Subtotal Stake: <b className="word_black">{Utils.formatNumberWithCommas(orderStake)}</b></span>
                    {orderWinLoss ? <span>Subtotal Win / Loss: <b className={`word_black ${orderWinLoss < 0 ? "word_red" : "word_green"}`}>{Utils.formatNumberWithCommas(orderWinLoss)}</b></span> : null}
                </li>
                <li className="ope_txt_time">
                    <span>{statementBet.OrderId}</span>
                    <span>{moment(statementBet.CreatedTime).utcOffset(state.CustInfo?.UtcOffset ?? -4).format('Do MMM. YYYY, H:mm Z')}</span>
                </li>
                {statementBet.Reason ? <li className="load_inorder">
                    <i className="fa-solid fa-circle-info">&nbsp;</i>
                    <span>
                        {statementBet.Reason}
                    </span>
                </li> : null}
            </ul>
        </div>
    )
};