
import React from 'react';
import styles from './index.module.scss';

interface LabInputProps {
    inputRef?: React.Ref<any>;
    className?: string;
    type: string;
    autoComplete?: string;
    minLength?: number;
    required?: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    tooltip: string;
}

const InputLabel: React.FC<LabInputProps> = ({
    inputRef,
    className,
    type,
    autoComplete,
    minLength,
    required,
    value,
    onChange,
    tooltip
}) => {
    // Rest of the component code

    return (
        <label className={`${styles.labInput} ${className}`}>  
            <input
                ref={inputRef}
                className={styles.userid}
                type={type}
                autoComplete={autoComplete}
                minLength={minLength}
                required={required}
                value={value}
                onChange={onChange}
            />
            <span className={`${styles.textInput} textInput`} data-tooltip={tooltip}></span>
        </label>
    );
};

export {InputLabel};
