import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const ToTopic: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <i className={`fa-solid fa-angles-up ${styles.buttonToTopic} ${showButton && styles.showButton}`} onClick={scrollToTop}></i>
  );
};

export default ToTopic;
