import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { LoginInfo } from '../models/LoginInfo';
import { HandleErrorType, HandleEvent } from "./HandleEvent";
import { OddsType } from "../enums";
import { IResponse } from "./IResponse";
import { ActionType } from "../components/AppContext";

const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        "Content-Type": "application/json",
    },
});

const setupInterceptorsTo = (api: AxiosInstance, handleEvent: HandleErrorType) => {
    api.interceptors.response.use(
        (response: AxiosResponse) => response,
        handleEvent.onResponseError
    );
};

const cancelTokenMap = new Map<string, CancelTokenSource>();

export const service = (dispatch: any) => {
    const handleEvent = HandleEvent(dispatch);
    setupInterceptorsTo(api, handleEvent);

    const postRequest = async <T>(url: string, data?: any, cancelPreviousRequest: boolean = false): Promise<T> => {
        let cancelTokenSource: CancelTokenSource | undefined = undefined;
        if (cancelPreviousRequest) {
            const previousTokenSource = cancelTokenMap.get(url);
            previousTokenSource && previousTokenSource.cancel("Cancel previous request.");

            cancelTokenSource = axios.CancelToken.source();
            cancelTokenMap.set(url, cancelTokenSource);
        }

        const response = await api.post(url, data, { cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined });
        return response.data;
    };

    return {
        userLogin: (login: LoginInfo): Promise<IResponse> => postRequest(`/api/customer/auth`, login),
        userInfo: (): Promise<IResponse> => postRequest(`/api/customer/cust_info`),
        logout: (): Promise<IResponse> => postRequest(`/api/customer/logout`),
        getMatch: (matchMapId: number): Promise<IResponse> => postRequest(`/api/odds/match`, { matchMapId }),
        getMarket: (matchMapId: number): Promise<IResponse> => postRequest(`/api/odds/markets`, { matchMapId }),
        search: (bodyData: any): Promise<IResponse> => postRequest(`/api/odds/matches`, bodyData),
        placeOrder: (bodyData: any): Promise<IResponse> => postRequest(`/api/orders/place`, bodyData),
        getOrder: (orderId: string): Promise<IResponse> => postRequest(`/api/orders/get`, { orderId }),
        checkOrder: (matchMapId: number): Promise<IResponse> => postRequest(`/api/orders/check`, { matchMapId }),
        getUnfilled: (filterType: number): Promise<IResponse> => postRequest(`/api/orders/get_unsettled`, { filterType }),
        deleteOrder: (orderId: string): Promise<IResponse> => postRequest(`/api/orders/delete`, { orderId }),
        stopOrder: (orderId: string): Promise<IResponse> => postRequest(`/api/orders/stop`, { orderId }),
        setOddsType: (oddsType: OddsType): Promise<IResponse> => postRequest(`/api/customer/set_oddstype`, { oddsType }),
        getStatement: (): Promise<IResponse> => postRequest(`/api/statement/get`),
        getBetDetail: (statementDate: string, filterType: number): Promise<IResponse> => postRequest(`/api/statement/detail`, { statementDate, filterType }),
        getSystemState: (): Promise<IResponse> => postRequest(`/api/company/get_state`),
        changePwd: (oldPassword: string, newPassword: string): Promise<IResponse> => postRequest(`/api/customer/change_pwd`, { oldPassword, newPassword }),
        noticeConut: async (): Promise<void> => {
            try {
                const response = await postRequest<IResponse>("/api/void_notice/conut", undefined, true);
                if (response.Status === 0) {
                    dispatch({ type: ActionType.SetNoticeConut, NoticeConut: response.Data });
                }
            } catch (error) {
                console.error("Failed to fetch notice count:", error);
            }
        },
        setRead: (betIds: number[]): Promise<IResponse> => postRequest<IResponse>("/api/void_notice/set_read", { betIds }),
    };
};