import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import CheckboxDropdownList from '../../components/CheckboxDropdownList';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { StatementSummary } from '../../components/StatementSummary';
import { StatementDetail } from '../../components/StatementDetail';
import './index.scss';

export const Statement = () => {
    const { settle_date, filterType } = useParams();
    const navigate = useNavigate();

    const [select, setSelect] = useState<string[]>([]);
    const list = ['Apple', 'Berry', 'Cherry', 'Orange', 'Grapes', 'Mango', 'Banana', 'Tomato'];

    const today: DateObject = new DateObject().setDay(15);
    const [value, setValue] = useState([today, today]);
    const handleDateChange = (selectedDate: Array<DateObject>) => {
        setValue(selectedDate);
    };

    return (
        <div className="box_l">
            <div className="all_outside">
                <div className="tool_title48">
                    {filterType !== '2' && settle_date ? (
                        <button className="btn_tool_back minimouse_hover" onClick={() => { navigate(-1) }}>
                            <i>
                                <svg>
                                    <path d="M15.066 7.09c.263 0 .487.097.674.29a.881.881 0 0 1 .259.684.861.861 0 0 1-.29.601.89.89 0 0 1-.622.25H3.212l4.476 4.31c.18.18.273.393.28.642a.842.842 0 0 1-.259.643.87.87 0 0 1-.663.29.89.89 0 0 1-.622-.249L.29 8.645A.858.858 0 0 1 0 7.992c0-.256.097-.473.29-.653l6.093-5.885a.99.99 0 0 1 .663-.249c.249 0 .46.093.632.28a.93.93 0 0 1 .26.653.847.847 0 0 1-.291.642L3.171 7.09h11.895z"></path>
                                </svg>
                            </i>
                        </button>
                    ) : null}
                    <span className="tool_title_txt">{filterType === '2' ? "Voided Bets" : (settle_date ? "Order Detail" : "Statement Summary")}</span>
                </div>
                {/*<div className="optionBar">
                    <div className="box">
                        <CheckboxDropdownList  
                            select={select}
                            setSelect={setSelect}
                            items={list} />
                    </div>
                    <div className="box">
                        <DatePicker
                            value={value}
                            onChange={handleDateChange}
                            range
                            numberOfMonths={2}
                            plugins={[
                                <Footer position="bottom" />
                            ]}
                        />
                    </div>
                        </div>*/}
                {settle_date || filterType === '2' ? <StatementDetail /> : <StatementSummary />}
            </div>
        </div>
    );
};