import { AxiosError } from "axios";
import { ActionType } from "../components/AppContext";

export interface HandleErrorType {
    onResponseError: (error: AxiosError) => Promise<AxiosError>;
}

export const HandleEvent = (dispatch: any): HandleErrorType => ({
    onResponseError: (error: AxiosError): Promise<AxiosError> => {
        if (!error.response) {
            
        } else if (error.response.status === 401) {
            dispatch({ type: ActionType.Logout });
            console.log("401 Unauthorized");
        } else if (error.response.status > 399) { // 401: Unauthorized 權限 處理
            // >= 400: Client Error 用戶端錯誤 處理 & LOG & Alert || Toast
            // 這類錯誤是客戶端的問題，所以我們需要在客戶端處理。
        } else if (error.response.status > 499) {
            // >= 500: Server Error 伺服器錯誤 處理 & Toast
            // 一般來說，這種錯誤是伺服器的問題，所以我們不需要在客戶端做任何處理，只需要在伺服器端處理好錯誤即可。
            // 但是，如果你的伺服器是由第三方提供，那麼你就需要在客戶端顯示錯誤訊息，讓使用者知道發生了什麼事情。
        }

        return Promise.reject(error);
    }
});
