import { useEffect, useRef, useState } from "react";
import OrderItem from "../../components/UnfilledItem";
import { Order } from "../../models/Order";
import { service } from "../../services";
import { useAppContext } from "../../components/AppContext";
import Utils from "../../utils/Util";
import { useParams } from "react-router-dom";

export const Unfilled = () => {
    const [orders, setOrders] = useState<Order[] | undefined>(undefined);
    const timeoutIDRef = useRef<number>(0);
    const { dispatch } = useAppContext();
    const api = service(dispatch);
    const { filterType } = useParams();
    let totalStake: number | undefined = undefined;

    useEffect(() => {
        if (!orders) {
            api.getUnfilled(parseInt(filterType ?? "0"))
                .then((response) => {
                    if (response.Status === 0 && response.Data) {
                        setOrders(response.Data);
                    }
                }).catch((error) => {

                });
        } else {
            timeoutIDRef.current = window.setTimeout((() => RefreshOrder({ ...orders })), 5000);
        }
        return () => {
            if (timeoutIDRef.current !== 0)
                window.clearTimeout(timeoutIDRef.current);
        };
    }, [orders]);

    useEffect(() => {
        return () => {
            setOrders(undefined);
        };        
    }, [filterType]);

    const RefreshOrder = (orders: Order[]) => {
        api.getUnfilled(parseInt(filterType ?? "0"))
            .then((response) => {
                let data = response;
                if (data.Status === 0 && data.Data) {
                    orders = data.Data;
                }
            }).finally(() => {
                setOrders(orders);
            });
    };

    //<OrderItem order={{}} />

    let ordersList = orders?.map((order, index) => {
        let stake: number = order.Stake ?? 0;
        if (filterType === "3") {
            stake = order.OrderLines.reduce((sum, model) => sum + (model.Stake ?? 0), 0);
        }
        totalStake = totalStake ? totalStake + stake : stake;
        return <OrderItem key={index} order={order} />;
    });

    let titleStr = "Rejected Bets";
    if (filterType === "1") {
        titleStr = "Rejected Bets";
    } else if (filterType === "2") {
        titleStr = "Voided Bets";
    } else if (filterType === "3") {
        titleStr = "Open Bets";
    }

    return (
        <div className="box_l" style={/*{ paddingTop: "55px" }*/undefined}>
            <div className="all_outside">
                {/* title  */}
                <div className="tool_title48">
                    <span className="tool_title_txt">{titleStr}</span>
                </div>
                {/* selection */}
                {ordersList}
                <div id="info_box" className="ope_info_box">
                    {/* <!-- foot --> */}
                    <div id="total_accounts" className="ope_info_foot">
                        <span>Total:</span>
                        <span id="amout_gold" className="word_white">{Utils.formatNumberWithCommas(totalStake ?? 0)}</span>
                    </div>
                    {/* <!-- foot end --> */}
                </div>
                {/*<div className="loading">
                    <i className="icon_load"></i>
                </div>*/}
            </div>
        </div>
    );
};