import { useEffect, useState } from "react";
import moment from "moment";
import { Statement } from "../../models/Satement";
import { service } from "../../services";
import { useAppContext } from "../AppContext";
import Utils from "../../utils/Util";
import { useNavigate, useParams } from "react-router-dom";

export const StatementSummary = () => {
    const { filterType } = useParams();
    const [statement, setStatement] = useState<{ [key: string]: Statement } | undefined>(undefined);
    const { state, dispatch } = useAppContext();
    const navigate = useNavigate();
    const api = service(dispatch);

    useEffect(() => {
        api.getStatement()
            .then((response) => {
                if (response.Status === 0 && response.Data) {
                    setStatement(response.Data);
                }
            }).catch((error) => {

            });
    }, []);

    let statementList = undefined;
    let totalStake: number | undefined = undefined;
    let totalWinLoss: number | undefined = undefined;
    const gotToStatementDetail = (dateStr: string) => {
        navigate(`/statement/${filterType}/${dateStr}`);
    };

    if (statement !== undefined) {
        const today = moment().utcOffset(-4);
        const dateRangeArray: string[] = [];

        for (let i = 0; i < 7; i++) {
            const currentDate = today.clone().subtract(i, 'days');
            const formattedDate = currentDate.format("YYYY-MM-DD");
            dateRangeArray.push(formattedDate);
        }

        statementList = dateRangeArray.map((dateStr) => {
            let statementItem = statement[dateStr];

            if (statementItem) {
                totalStake = totalStake ? totalStake + statementItem.Stake : statementItem.Stake;
                totalWinLoss = totalWinLoss ? totalWinLoss + statementItem.WinLoss : statementItem.WinLoss;
            }
            return <tr key={dateStr} onClick={statementItem ? () => gotToStatementDetail(dateStr) : undefined} style={statementItem ? { cursor: "pointer" } : undefined}>
                <td className={statementItem ? "date word_bold" : "date"}>
                    {moment(dateStr).format("MM-DD")}<br />
                    {moment(dateStr).format("dddd")}
                </td>
                <td>
                    <b>{statementItem ? Utils.formatNumberWithCommas(statementItem.Stake) : "-"}</b>
                </td>
                <td>
                    {statementItem ?
                        <b className={statementItem.WinLoss < 0 ? "word_red" : "word_green"}>{Utils.formatNumberWithCommas(statementItem.WinLoss)}</b>
                        :
                        <b className="winloss_black">-</b>
                    }
                </td>
            </tr>;
        });
    }

    return (
        <div>
            <div className="his_info_box">
                <table cellPadding="0" cellSpacing="0" className="his_info_tb">
                    <thead>
                        <tr className="dark_head">
                            <th style={{ width: "34%" }} className="date">Date</th>
                            <th style={{ width: "33%" }}>Stake Amount</th>
                            <th style={{ width: "33%" }}>Win / Loss</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statementList}
                    </tbody>
                    <tfoot>
                        <tr className="dark_head word_bold">
                            <th className="date">
                                <b className="word_lightwhite">Total:</b>
                            </th>
                            <th>
                                {Utils.formatNumberWithCommas(totalStake ?? 0)}
                            </th>
                            <th>
                                <b className={totalWinLoss ?? 0 < 0 ? "word_lightred" : "word_lightgreen"}>{Utils.formatNumberWithCommas(totalWinLoss ?? 0)}</b>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};


