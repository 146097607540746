import './Contianer.scss';
import '../style/button.scss';
import { useAppContext } from "./AppContext";
export const Contianer = () => {
    const { state } = useAppContext();
    return (
        <>{state.Container ?
            <div className="popup_center popup_kick on">
                <div>
                    {state.Container}
                </div>
            </div> : null}
        </>
    );
}