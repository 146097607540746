import { useState, useEffect, FormEvent } from 'react';
import './index.scss';
import { Match } from '../../models/Match';
import { Order, OrderLine } from '../../models/Order';
import { Choice, MarketType, OddsType, OrderStatus } from '../../enums';
import MarketUtils from '../../utils/MarketUtils';
import { ActionType, useAppContext } from '../../components/AppContext';
import { service } from '../../services';
import OddsUtils from '../../utils/OddsUtils';
import moment from 'moment';
import Utils from '../../utils/Util';
import { InputNumber } from '../InputNumber';
import Refresh from '../Refresh';
import './bet_game.scss';
import { IResponse } from '../../services/IResponse';


export const OrderForm = ({ inOrder }: { inOrder: Order }) => {
    //const [orderLines, setOrderLines] = useState<OrderLine[]>([]);
    const { state, dispatch } = useAppContext();
    const [order, setOrder] = useState<Order>(inOrder);
    const [match, setMatch] = useState<Match>(inOrder.Match);
    const [orderLines, setOrderLines] = useState<OrderLine[]>(inOrder.OrderLines);
    const [stake, setStake] = useState<number | undefined>(undefined);
    const [waiting, setWaiting] = useState<boolean>(false);
    const [msg, setMsg] = useState<string | undefined>(undefined);
    let totalStake: number | undefined = undefined;

    useEffect(() => {
        if (msg)
            window.setTimeout((() => setMsg(undefined)), 5000);
    }, [msg]);

    const api = service(dispatch);
    useEffect(() => {
        let timeoutID = 0;
        if (order.OrderId !== null) {
            timeoutID = window.setTimeout((() => RefreshOrder({ ...order })), 500);
        }

        return () => {
            window.clearTimeout(timeoutID);
        };
    }, [order]);

    const marketType: MarketType = inOrder.MarketType;
    const choice: Choice = inOrder.Choice;
    const orderLineItems: OrderLine[] = (match.Markets ?? [])
        .filter(item => item.MarketType === marketType && item.Odds.some(odd => odd.Choice === choice))
        .map(item => {
            const targetOdd = item.Odds.find(odd => odd.Choice === choice);
            if (targetOdd) {
                return {
                    Line: item.Line,
                    Price: targetOdd.Price
                };
            }
            return null;
        })
        .filter(Boolean) as OrderLine[];

    const clickOrderLine = (match_line: OrderLine) => {
        let thisOrderLine: OrderLine | undefined = findOrderLine(match_line.Line);
        if (thisOrderLine !== undefined) {
            setOrderLines(orderLines.filter((orderLine) => orderLine.Line !== match_line.Line));
        } else {
            let updatedOrderLines = orderLineItems.map((l) => {
                let orderline = { ...l };
                if (orderline.Line === match_line.Line) {
                    const oddsType = state.CustInfo?.OddsType ?? OddsType.DECIMAL;
                    orderline.Price = OddsUtils.ConvertOddsType(orderline.Price, oddsType);
                    orderline.OddsType = oddsType;
                    return orderline;
                } else {
                    const foundLine = findOrderLine(l.Line);
                    if (foundLine) {
                        return foundLine;
                    }
                }
                return null;
            });
            setOrderLines(updatedOrderLines.filter((line): line is OrderLine => line !== null) || []);
        }
    };

    let findOrderLine = (line: number): OrderLine | undefined => {
        return orderLines.find((x: OrderLine) => x.Line === line);
    }

    let SetOrderLine = (price: number, line: number) => {
        var thisLine = orderLines.find((x: OrderLine) => x.Line === line);
        if (thisLine)
            thisLine.Price = price;
        setOrderLines([...orderLines]);
    }

    let orderLinesForm = undefined;

    if (order.OrderId !== null) {
        orderLinesForm = order.OrderLines.map((orderLine: OrderLine) => {
            let point: number = orderLine.Line;
            let strPoint: string = OddsUtils.ConvertPoint(marketType, point);
            let price = orderLine.Price;
            if (orderLine.Stake)
                totalStake = (totalStake ?? 0) + (orderLine.Stake ?? 0);
            return (
                <div key={orderLine.Line}>
                    <div className="btn_obt2_odd on">
                        <span>
                            <span className="text_ballhead">({strPoint})</span>
                        </span>
                        <span className="text_odds">{price}</span>
                        {orderLine.Stake ?
                            <span className="text_odds">{Utils.formatNumberWithCommas(orderLine.Stake)}</span>
                            : null}
                    </div>
                </div>
            );
        });
    } else {
        let tOrderLineItems: OrderLine[] = [...orderLineItems];
        orderLines.filter((oline) => {
            return !orderLineItems.some((item) => item.Line === oline.Line);
        }).forEach((oline) => {
            tOrderLineItems.push(oline);
        });

        orderLinesForm = tOrderLineItems.map((line: OrderLine) => {
            let point: number = choice === Choice.AWAY ? line.Line * -1 : line.Line;
            let strPoint: string = OddsUtils.ConvertPoint(marketType, point);

            let orderLine = findOrderLine(line.Line);
            const oddsType = state.CustInfo?.OddsType ?? OddsType.DECIMAL;
            let price = orderLine === undefined ? OddsUtils.ConvertOddsType(line.Price, oddsType) : orderLine.Price;
            return (
                <div key={line.Line}>
                    <div className={`btn_obt2_odd ${orderLine ? "on" : ""}`}>
                        <span onClick={() => clickOrderLine(line)}>
                            <label className="check_event new2">
                                <input type="checkbox" checked={orderLine !== undefined} onChange={() => clickOrderLine(line)} />
                                <span className="checkmark"></span>
                                ({strPoint})
                            </label>
                        </span>
                        {orderLine === undefined ?
                            <span className="text_odds" onClick={() => clickOrderLine(line)}>{price}</span>
                            :
                            <>
                                <InputNumber step={OddsUtils.OddsStep(oddsType)} min={OddsUtils.OddsMin(oddsType)} max={OddsUtils.OddsMax(oddsType)} placeholder="Enter price" value={price} style={{ textAlign: "right", color: "black", maxWidth: "100px" }} onChange={e => orderLine && SetOrderLine(parseFloat(e.target.value), orderLine.Line)} />
                                {/* <input type="number" step={OddsUtils.OddsStep(oddsType)} min={OddsUtils.OddsMin(oddsType)} max={OddsUtils.OddsMax(oddsType)} placeholder="Enter a price" value={price} className='importantColor' style={{ textAlign: "right", width: "70px", color: "black" }} onChange={e => orderLine && SetOrderLine(parseFloat(e.target.value), orderLine.Line)} /> */}
                            </>}
                    </div>
                </div>
            );
        });
    }

    const PlaceOrder = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (orderLines.length === 0) {
            setMsg("Please select at least one line");
            return;
        }

        let placeOrderLines: OrderLine[] = orderLines;

        if (choice === Choice.AWAY) {
            placeOrderLines = orderLines.map((line: OrderLine) => {
                return { ...line, Line: line.Line * -1 };
            });
        }
        setWaiting(true);
        api.placeOrder({
            "matchMapId": order.MatchMapId,
            "marketType": marketType,
            "choice": choice,
            "orderLines": placeOrderLines,
            "stake": stake
        }).then((response) => {
            let data = response;
            if (data.Status === 0 && data.Data.OrderId) {
                let _Order = data.Data as Order;
                setOrder(_Order);
            } else if (data.Status === 110 && data.Data.OrderId) {
                let _Order = data.Data as Order;
                setOrder(_Order);
                setMsg(data.Message);
            }
        }).finally(() => {
            setWaiting(false);
        });
    };

    const RefreshOrder = (_Order: Order) => {
        api.getOrder(_Order?.OrderId ?? "")
            .then((response) => {
                let data = response;
                if (data.Status === 0 && data.Data.OrderId) {
                    _Order = data.Data as Order;
                }
            }).finally(() => {
                setOrder(_Order);
            });
    };

    const RefreshMatch = async (match: Match) => {
        await api.getMatch(match.MatchMapId)
            .then((response) => {
                let data = response;
                if (data.Status === 0 && data.Data) {
                    match = data.Data;
                }
            }).finally(() => {
                setMatch(match);
            });
    };

    const handleOrderAction = (action: () => Promise<IResponse>) => {
        setWaiting(true);
        action()
            .then((res) => {
                if (res.Status === 0) {
                    alert("OK");
                    setTimeout(() => {
                        console.log('setWaiting');
                        setWaiting(false);
                    }, 10000);
                } else if (res.Data) {
                    let _Order = res.Data as Order;
                    setOrder(_Order);
                }
            }).finally(() => {
                setWaiting(false);
            });
    };
    const DeleteOrder = () => {
        // var tf = window.confirm("Do you want to delete the order?");
        // if (tf) {
            handleOrderAction(() => api.deleteOrder(order?.OrderId ?? ""));
        // }
    };
    const StopOrder = () => {
        handleOrderAction(() => api.stopOrder(order?.OrderId ?? ""));
    };

    let orderStatus: OrderStatus = order.Status;
    const oddsType = state.CustInfo?.OddsType ?? OddsType.DECIMAL;
    const gameTime = moment(match.GameTime);

    return (
        <form onSubmit={waiting ? undefined : PlaceOrder}>
            {/* TEST */}
            <div className="popup_title">
                <span><div className='textEllipsis' style={{ textAlign: "center" }}>{match.LeagueName}<Refresh
                    style={{
                        position: "absolute",
                        right: "8px",
                        top: "8px"
                    }}
                    callback={async () => { await RefreshMatch({ ...match }) }} secs={match.IsLive ? 10 : 30} /></div></span>
            </div>
            <div id="div_matches" className="box_scoboard_r tn" >
                <div className="box_scostate">
                    {/* <i id="midfield" className="icon_n" ></i> */}
                    {
                        match.IsLive ?
                            <b className='text_time'>{match.TimeInfo}</b>
                            :
                            <b className="text_time">{gameTime.isSame(moment(), 'day') ? gameTime.format('HH:mm') : gameTime.format('YYYY/MM/DD HH:mm')}</b>
                    }
                </div>
                <div className="box_sco_l">
                    <div className="box_scoteam team_h">
                        <span id="team_h" className="text_team">{match.HomeTeam}</span>
                    </div>
                    {
                        match.IsLive ?
                            <b className='game_time'>({match.LiveScore?.[0] ?? 0} - {match.LiveScore?.[1] ?? 0})</b>
                            :
                            <i className="fa-solid fa-v box_sco_vs"></i>
                    }
                    <div className="box_scoteam team_c">
                        <span id="team_c" className="text_team">{match.AwayTeam}</span>
                    </div>
                </div>
            </div>
            {/* TEST */}
            <div className="popup_content paddZero">
                {order.OrderId !== null &&
                    <div className="game_order_info"> 
                        <p >Order ID: <b>{order.OrderId}</b></p> 
                        <p>Status: <b className={`badge ${MarketUtils.OrderStatusClass[orderStatus]}`}>{MarketUtils.OrderStatusName[orderStatus]}</b></p>
                        {order.Reason && 
                            <div style={{ fontSize: "18px", color: "red" }}>Reason: {order.Reason}</div> 
                        }
                    </div>
                }
                <div className="table_obt_re reForm">
                    <div className="table_obt_title">{MarketUtils.MarketTypeName[marketType]}</div>
                    <div className="table_obt_team teamH strong_team">{MarketUtils.ChoiceName(choice, match)}</div>
                    <div id="table_obt_bet_r" className="table_obt_bet">
                        {orderLinesForm}
                    </div>
                </div>
                <div className="oddsBox">
                    {order.OrderId !== null ? <>
                        {totalStake ?
                            <div className='totalStake'>Total Stake = {Utils.formatNumberWithCommas(totalStake)}</div>
                            :
                            <div className='totalStake'>Maximum Stake = {Utils.formatNumberWithCommas(order.Stake ?? 0)}</div>}
                    </> :
                        <input type="text" className="orderInput pc_typing on" autoComplete="off" placeholder="Enter Maximum Stake" maxLength={13} value={stake} onChange={(e) => {
                            if (e.target.value === "") {
                                setStake(undefined);
                            } else if (!isNaN(Number(e.target.value))) {
                                setStake(parseFloat(e.target.value))
                            }
                        }
                        } />}

                    <div className="oddsType">
                        {/*<button type="button" className={(oddsType === OddsType.MALAY) ? "checkBotton on" : "checkBotton"} onClick={oddsType === OddsType.MALAY ? undefined : () => { dispatch({ type: ActionType.SetOddsType, OddsType: OddsType.MALAY }) }}>Malay</button>*/}
                        {/*<button type="button" className={(oddsType === OddsType.HK) ? "checkBotton on" : "checkBotton"} onClick={oddsType === OddsType.HK ? undefined : () => { dispatch({ type: ActionType.SetOddsType, OddsType: OddsType.HK }) }}>HK</button>*/}
                        <button type="button" className={(oddsType === OddsType.DECIMAL) ? "checkBotton on" : "checkBotton"} onClick={oddsType === OddsType.DECIMAL ? undefined : () => { dispatch({ type: ActionType.SetOddsType, OddsType: OddsType.DECIMAL }) }}>DEC</button>
                    </div>
                </div>
            </div>
            {msg ? <div className="ord_err">{msg}</div> : null}
            <div className="buttonBar">
                {order.OrderId === null ? <button type="submit" className="btn_submit" disabled={waiting}>Place Order</button> : null}
                {order.OrderId !== null && orderStatus === OrderStatus.PENDING &&
                    <button type="button" className="btn_delete" onClick={waiting ? undefined : DeleteOrder} disabled={waiting}>Delete Order</button>
                }
                {order.OrderId !== null && orderStatus === OrderStatus.RUNNING &&
                    <button type="button" className="btn_stop" onClick={waiting ? undefined : StopOrder} disabled={waiting}>Stop Order</button>
                }
                <button type="button" className="btn_cancel" onClick={() => { dispatch({ type: ActionType.SetContainer, Container: undefined }) }}>Close</button>
            </div>
        </form>
    );
};